import * as React from 'react';
import { Helmet } from "react-helmet";
import * as fflate from 'fflate';
import ElapsedTime from "elapsed-time"

import Layout from "../components/layout"

import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import GridItem from '../components/GridItem'
import Button from '@mui/material/Button'
import FormLabel from '@mui/material/FormLabel'
import Paper from '@mui/material/Paper'

export default function Compression() {

  const [targetPlainText, setTargetPlainText] = React.useState("");
  const [outHashText, setOutHashText] = React.useState("");
  const [outHexText, setOutHexText] = React.useState("");
  const [metrics, setMetrics] = React.useState({
    elapsed: 0,
    compressionRatio: 0,
    originalSize: 0,
    outputSize: 0,
  })

  const doCompress = () => {    
    const buf = fflate.strToU8(targetPlainText);
    const start = ElapsedTime.new().start();
    const compressed = fflate.compressSync(buf, { level: 6, mem: 8 });
    const elapsed = start.getValue();
    const originalSize = buf.byteLength;
    const outputSize = compressed.byteLength;

    setMetrics({
      elapsed: elapsed,
      compressionRatio: (( originalSize - outputSize ) / originalSize * 100).toFixed(2),
      originalSize: buf.byteLength,
      outputSize: compressed.byteLength
    });
    
    const outBuffer = Buffer.from(compressed);
    setOutHashText(outBuffer.toString("base64"));
    setOutHexText(outBuffer.toString("hex"));
  };

  return (
    <Layout>
      <Helmet>
        <title>Online GZIP Compression Tool</title>
        <meta name="keywords" content="gzip,online,compression" />
        <meta name="description" content="An online tool that takes plain text, compresses it using Gzip, and returns the result as base64-encoded output. The tool also allows users to check the compression ratio."/>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GridItem>
            <TextField
              label="Plain Text(UTF-8)"
              multiline
              rows={4}
              fullWidth
              onChange={(event) => { setTargetPlainText(event.target.value); }}
              value={targetPlainText}
            />
            <Button
              fullWidth variant="contained" size="large"
              onClick={doCompress}
              sx={{ marginTop: 2 }}
            >
              GZIP Compress
            </Button>
            <p>
              Took {metrics.elapsed}, Size: {metrics.originalSize}bytes &gt; {metrics.outputSize}bytes, Compression Ratio: {metrics.compressionRatio}%
            </p>
            <TextField             
              label="Hash Base64"
              multiline
              rows={4}
              fullWidth
              value={outHashText}
              sx={{ marginTop: 2 }}
            />
            <TextField
              label="Hash Hex"
              multiline
              rows={4}
              fullWidth
              value={outHexText}
              sx={{ marginTop: 2 }}
            />
          </GridItem>
        </Grid>
      </Grid>
      <Paper sx={{ p: 5 }}>
        <h2>How to use</h2>
        <ol>
          <li>Enter plain text in the text box.</li>
          <li>Click the "GZIP Compress" button.</li>
          <li>Hash Base64 and Hash Hex are the compressed results.</li>
          <li>Compression Ratio is the ratio of the original size to the compressed size.</li>
        </ol>    
      </Paper>
    </Layout>
  );
}
